import { REHYDRATE } from 'redux-persist';
import {
  REPORT_PRE_QUAL
} from '../actions';
import _ from 'lodash'

const INIT_STATE = {
  summary: null,
  invited: null
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORT_PRE_QUAL.SUCCESS: {
      return { ...state, [action.payload.report]: action.payload.url }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
