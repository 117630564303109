import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  REPORT_PRE_QUAL
} from '../actions';

export function* watchPreQualUrlRequest() {
  const action = REPORT_PRE_QUAL
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/reports/${payload.report}/dashboard`
      }
    },
    dataPath: 'url',
    dataDefault: null,
    beforeSuccess: (data, state, payload) => {
      return {
        report: payload.report,
        url: data
      }
    }
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchPreQualUrlRequest),
  ]);
}